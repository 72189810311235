import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('globalStore', () => {
  const { t } = useI18n()
  const route = useRoute()
  const menu = computed(() => {
    return [
      {
        titleTw: '設計案例',
        titleEn: 'Project',
        link: '/scope',
        sub: [
          {
            title: t('menu.project_scope'),
            link: '/scope'
          },
          {
            title: t('menu.project_space'),
            link: '/space'
          },
          {
            title: t('menu.project_art'),
            link: '/art'
          }
        ]
      },
      {
        titleTw: '品牌故事',
        titleEn: 'Story',
        link: '/furniture-stories',
        sub: [
          {
            title: t('menu.furniture-stories'),
            link: '/furniture-stories'
          },
          {
            title: t('menu.interior-stories'),
            link: '/interior-stories'
          },
          {
            title: t('menu.product-stories'),
            link: '/product-stories'
          }
        ]
      },
      {
        titleTw: '人物觀點',
        titleEn: 'Opinion',
        link: '/opinion'
      },
      {
        titleTw: '最新消息',
        titleEn: 'News',
        link: '/news'
      },
      {
        titleTw: '傢飾選物',
        titleEn: 'IW Select',
        link: '/iwselect'
      },
      {
        titleTw: '雜誌期刊',
        titleEn: 'IW Issue',
        link: '/issue',
        sub: [
          {
            title: t('menu.issue_sub1'),
            link: '/issue'
          },
          {
            title: t('menu.issue_sub2'),
            link: '/issue-detail'
          }
        ]
      }
      // {
      //   titleTw: '全球競賽',
      //   titleEn: 'Competition',
      //   link: '/competition',
      //   sub: [
      //     {
      //       title: t('menu.competition_sub1'),
      //       link: '/competition'
      //     },
      //     {
      //       title: t('menu.competition_sub2'),
      //       link: '/iwcompetition'
      //     }
      //   ]
      // }
    ]
  })

  const isMobileMenuShow = ref(false)
  const isHeaderSearchShow = ref(false)

  const searchText = ref('')

  const isLogin = ref(false)
  const isCartShow = ref(false)

  // 頁面切換關閉購物車
  watch(
    () => route.path,
    () => {
      isCartShow.value = false
      isMobileMenuShow.value = false
    }
  )

  return {
    menu,
    isMobileMenuShow,
    isHeaderSearchShow,
    searchText,
    isLogin,
    isCartShow
  }
})
