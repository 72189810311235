<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { IAddCartParams } from '~/api/cart';

const { cart } = useApi()
const store = useGlobalStore()
const userStore = useUserStore()
const cartStore = useCartStore()
const { isCartShow } = storeToRefs(store)

const selectLists = computed(() => cartStore.selectCart)
const issueLists = computed(() => cartStore.issueCart)

function handelClickOther(event: any) {
  if (isCartShow.value) {
    var isClickInsideCart = event?.target?.closest('.cart');

    if (!isClickInsideCart) {
      isCartShow.value = false
    }
  }
}

watch(
  () => isCartShow.value,
  (v) => {
    if (v) {
      cart.getSelectCart({ session_id: userStore.sessionId }).then((res) => {
        cartStore.setSelectCart(res.data.value)
      })

      cart.getISsueCart({ session_id: userStore.sessionId }).then((res) => {
        cartStore.setISsueCart(res.data.value)
      })
    }
  }
)

onMounted(() => {
  if (process.client) {
    document.addEventListener('click', handelClickOther)
  }
})

onUnmounted(() => {
  if (process.client) {
    document.removeEventListener('click', handelClickOther)
  }
})
const storageUrl = import.meta.env.VITE_STORAGE_URL;
</script>

<template>
  <section class="cart" :class="{ show: isCartShow }">
    <div class="cart__top">
      <div class="cart__top__title">我的購物車</div>
      <button class="buttonReset cart__top__close" @click="isCartShow = false">
        <svg-icon name="icon-close" />
      </button>
    </div>
    <div class="cart__lists">
      <ul class="lists">
        <li v-if="selectLists?.items">
          <div class="lists__subtitle">選物</div>
          <div class="lists__item" v-for="(item, index) in selectLists.items" :key="index">
            <figure class="lists__pic">
              <NuxtImg :src="`${storageUrl}/${item.attributes.spec_image}`"></NuxtImg>
            </figure>
            <div class="lists__right">
              <button class="buttonReset lists__delete" @click="delSelectCartItem(item.id)">
                <span class="lists__delete__item">
                  <svg-icon name="icon-close" />
                </span>
              </button>
              <nuxt-link class="lists__title" :to="`/iwselect/${item.attributes.selection_id}`">
                {{ item.name }}
              </nuxt-link>
              <div class="lists__bottom">
                <div class="lists__color">
                  <div class="lists__color__item" :style="{ backgroundColor: item.attributes.spec_color }"></div>
                </div>
                <div class="lists__price">{{ $t('currency') }}{{ item.price }}</div>
                <div class="lists__count">
                  <Count v-model:qty="item.quantity" :item-id="item.id" :updateCart="updateSelectCartQty" />
                </div>
              </div>
            </div>
          </div>
          <div class="lists__total">
            <div class="lists__total__title">總計</div>
            <div class="lists__total__price">{{ $t('currency') }}{{ selectLists.total }}</div>
          </div>
        </li>
        <li v-if="issueLists?.items">
          <div class="lists__subtitle">期刊</div>
          <div class="lists__item" v-for="(item, index) in issueLists.items" :key="index">
            <figure class="lists__pic">
              <NuxtImg :src="`${storageUrl}/${item.attributes.image}`"></NuxtImg>
            </figure>
            <div class="lists__right">
              <button class="buttonReset lists__delete" @click="delIssueCartItem(item.id)">
                <span class="lists__delete__item">
                  <svg-icon name="icon-close" />
                </span>
              </button>
              <nuxt-link class="lists__title" :to="`/iwselect/${item.attributes.selection_id}`">
                {{ item.name }}
              </nuxt-link>
              <div class="lists__bottom">
                <!-- <div class="lists__color">
                  <div class="lists__color__item" :style="{ backgroundColor: item.attributes.spec_color }"></div>
                </div> -->
                <div class="lists__price">{{ $t('currency') }}{{ item.price }}</div>
                <div class="lists__count">
                  <Count v-model:qty="item.quantity" :item-id="item.id" :updateCart="updateIssueCartQty" />
                </div>
              </div>
            </div>
          </div>
          <div class="lists__total">
            <div class="lists__total__title">總計</div>
            <div class="lists__total__price">{{ $t('currency') }}{{ issueLists.total }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="cart__bottom">
      <ButtonLink2 to="/iwselect" title="繼續購物" />
      <ButtonLink2 to="/cart" title="結帳" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.cart {
  position: fixed;
  right: 0;
  top: 55px;
  width: 100%;
  max-width: 500px;
  padding: 30px 30px 50px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(20px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;

  @include max-media(500) {
    max-width: calc(100% - 20px);
  }

  &.show {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    margin-right: 30px;
    margin-bottom: 18px;
    border-bottom: 1px solid #e8e8e8;

    @include max-media(480) {
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    &__title {
      font-size: 16px;
      font-weight: 700;

      @include max-media(480) {
        font-size: 14px;
      }
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;

      svg {
        width: 10px;
        height: 10px;

        @include max-media(480) {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .lists {
    padding-right: 30px;
    max-height: 700px;
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #d9d9d9;
    }

    &::-webkit-scrollbar-button {
      background: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    @include max-height-media(940) {
      max-height: 500px;
    }

    @include max-height-media(780) {
      max-height: 350px;
    }

    li {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #e8e8e8;
    }

    &__item {
      display: flex;
      width: 100%;
      margin-bottom: 40px;

      @include max-media(480) {
        margin-bottom: 30px;
      }
    }

    &__subtitle {
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 35px;

      @include max-media(480) {
        font-size: 12px;
        margin-bottom: 25px;
      }
    }

    &__pic {
      width: 136px;

      @include max-media(480) {
        width: 105px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &__right {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 50px;
      font-size: 16px;
      width: calc(100% - 136px);

      @include max-media(480) {
        width: calc(100% - 105px);
        padding-left: 25px;
        font-size: 12px;
      }
    }

    &__delete {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border: 1px solid rgba(#000, 0.8);
        border-radius: 50%;

        @include max-media(480) {
          width: 14px;
          height: 14px;
        }

        svg {
          width: 6px;
          height: 6px;

          @include max-media(480) {
            width: 4px;
            height: 4px;
          }
        }
      }
    }

    &__title {
      padding-right: 30px;

      &:hover {
        text-decoration: underline;
      }
    }

    &__bottom {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 265px;
      margin-top: auto;
    }

    &__color {
      width: 100%;
      margin-bottom: 15px;

      &__item {
        width: 17px;
        height: 17px;
      }
    }

    &__count {
      width: 105px;
      margin-left: auto;

      @include max-media(480) {
        transform: scale(0.85);
        transform-origin: right center;
      }

      :deep(.count) {
        border-color: #cecece;
      }

      :deep(.count__number) {
        font-size: 10px;
      }
    }

    &__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 40px;
      font-size: 16px;
    }
  }

  &__bottom {
    margin-right: 30px;
    display: flex;
    justify-content: space-between;

    :deep(.link) {
      min-width: 180px;
      text-align: center;
      font-size: 15px;

      @include max-media(480) {
        min-width: 140px;
        font-size: 10px;
      }

      @include max-media(390) {
        min-width: auto;
      }
    }
  }
}
</style>
