<script lang="ts" setup>
import { type MenuList } from "@/utils/types";
const lists: Ref<MenuList[]> = ref([
  {
    title: "About us",
    link: "/about",
  },
  {
    title: "Privacy policy",
    link: "/privacy",
  },
  {
    title: "Terms of service",
    link: "https://policies.google.com/terms?hl=zh-TW",
    target: "_blank",
  },
  // {
  //   title: "Shipping policy",
  //   link: "/shipping",
  // },
  {
    title: "Contact",
    link: "/contact",
  },
]);
</script>

<template>
  <div class="footerLeft">
    <ul class="footerLeft__lists">
      <li v-for="(item, index) in lists" :key="index">
        <nuxt-link :to="item.link" :target="item.target">{{
          item.title
        }}</nuxt-link>
      </li>
    </ul>
    <div class="footerLeft__copyright">
      © 2023, IW MAGAZINE. ALL RIGHTS RESERVED
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footerLeft {
  @include max-media(1024) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__lists {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;
    li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        &::after {
          content: "‧";
          padding-left: 2px;
          padding-right: 2px;
        }
      }
    }
    a {
      font-size: 10.8px;
      font-family: $font-NotoSans;
      line-height: 1.5;
      color: rgba(#000, 0.8);
      @include max-media(480) {
        font-size: 8.5px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__copyright {
    font-size: 10.8px;
    font-family: $font-NotoSans;
    line-height: 1.5;
    color: rgba(#000, 0.8);
    @include max-media(480) {
      font-size: 8.5px;
    }
  }
}
</style>
