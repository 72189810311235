<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
import { storeToRefs } from 'pinia'
const store = useGlobalStore()
const { isHeaderSearchShow } = storeToRefs(store)
const route = useRoute()
const searchText = ref(decodeURIComponent(route.query.q as string || ''))

const handleSearch = () => {
  return navigateTo({ path: '/search', query: { q: encodeURIComponent(searchText.value) } })
}

const handleClose = () => {
  isHeaderSearchShow.value = false
  searchText.value = ''
}
</script>

<template>
  <div class="search" :class="{ show: isHeaderSearchShow }">
    <div class="wrap">
      <input v-model="searchText" type="text" placeholder="Search...." @keyup.enter="handleSearch" />
      <button class="buttonReset buttonSearch" @click="handleSearch">
        <svg-icon name="icon-search" />
      </button>
      <button class="buttonReset buttonDelete" @click="handleClose"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 55px;
  background-color: #fff;
  transform: translateY(-55px);
  transition: all 0.5s ease-in-out;

  &.show {
    transform: translateY(0);
  }

  .wrap {
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    height: 55px;
    padding-right: 10px;
    border: none;
    font-family: $font-NotoSans;

    &:focus {
      border-bottom: 1px solid #000;
    }
  }

  .buttonSearch,
  .buttonDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 30px;
  }

  .buttonSearch {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .buttonDelete {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -8px;
      margin-top: -0.5px;
      width: 16px;
      height: 1px;
      background-color: #000;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
</style>
