<script lang="ts" setup>
import HeaderBar from '@/components/Header/HeaderBar.vue'
import FooterBar from '@/components/Footer/FooterBar.vue'
const { cart } = useApi()
const userStore = useUserStore()
const cartStore = useCartStore()
const store = useGlobalStore()
const { isHeaderSearchShow } = storeToRefs(store)
cart.getSelectCart({ session_id: userStore.sessionId }).then((res) => {
  cartStore.setSelectCart(res.data.value)
})

cart.getISsueCart({ session_id: userStore.sessionId }).then((res) => {
  cartStore.setISsueCart(res.data.value)
})

const handleGotop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
</script>

<template>
  <section class="outerWrap">
    <Search />
    <HeaderBar />
    <main class="main" :class="{ searchShow: isHeaderSearchShow }">
      <slot name="content" />
    </main>
    <FooterBar />
    <MobileMenu />
    <button class="buttonReset buttonGotop" @click="handleGotop">
      <NuxtImg src="./images/icon_gotop.svg"></NuxtImg>
    </button>
  </section>
</template>

<style lang="scss" scoped>
.main {
  padding-top: 55px;

  &.searchShow {
    position: relative;
    z-index: 5;
  }
}

.buttonGotop {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 10;
  width: 30px;
  height: 30px;

  img {
    display: block;
    width: 100%;
  }
}
</style>
