<script lang="ts" setup></script>

<template>
  <div class="footerRight">
    <div class="footerRight__inner">
      <div class="footerRight__social">
        <nuxt-link to="https://www.facebook.com/iwmagazine.tw">
          <NuxtImg src="./images/icon_fb.png"></NuxtImg>
        </nuxt-link>
        <nuxt-link to="https://www.instagram.com/iw_magazine/">
          <NuxtImg src="./images/icon_ig.png"></NuxtImg>
        </nuxt-link>
        <nuxt-link to="https://lin.ee/7Ls0Jtf">
          <NuxtImg src="./images/icon_line.png"></NuxtImg>
        </nuxt-link>
      </div>
      <div class="footerRight__bottom">
        <nuxt-link to="mailto:Info@iw-magazine.com">Info@iw-magazine.com
        </nuxt-link>
        <nuxt-link to="tel:886-2-2501-9868">886-2-2501-9868</nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footerRight {
  display: flex;
  justify-content: flex-end;

  @include max-media(1024) {
    justify-content: center;
  }

  &__inner {
    @include max-media(1024) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a {
      width: 25px;
      height: 25px;
      margin-right: 13px;
      transition: all 0.5s ease-in-out;

      @include max-media(1024) {
        width: 20px;
        height: 20px;
        margin-left: 8px;
        margin-right: 8px;
      }

      &:hover {
        opacity: 0.7;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__bottom {
    @include max-media(1024) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    a {
      font-size: 10.8px;
      font-family: $font-NotoSans;
      line-height: 1.5;
      color: rgba(#000, 0.8);

      @include max-media(480) {
        font-size: 8.5px;
      }

      &:not(:last-child) {
        @include min-media(1025) {
          margin-right: 50px;
        }

        @include max-media(1024) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
