<script lang="ts" setup>
import { useGlobalStore } from "@/stores"
import { useI18n } from "vue-i18n"

const { locale, setLocale } = useI18n()
const store = useGlobalStore()
const { isMobileMenuShow, isCartShow } = storeToRefs(store)
const userStore = useUserStore()
const isLogin = computed(() => userStore.isLogin)

const isSubShow = reactive({
  index: 0,
  type: false
})

const handleToggle = (index: any, sub: Array<any>) => {
  if (isSubShow.index !== index) {
    isSubShow.type = true
  } else {
    isSubShow.type = !isSubShow.type
  }
  isSubShow.index = index;
  console.log(sub)
  if (!sub || sub.length === 0) {
    isMobileMenuShow.value = !isMobileMenuShow.value
  }
}

const closeMenu = () => {
  isMobileMenuShow.value = !isMobileMenuShow.value
}

</script>

<template>
  <section class="mobileBox" :class="{ isShow: isMobileMenuShow }">
    <ul class="mobileBox__menu">
      <li v-for="(item, index) in store.menu" :key="index">
        <nuxt-link :to="item.sub ? '#' : item.link" :title="item.titleTw" class="menu__top"
          @click="handleToggle(index, item.sub)">
          <span class="mobileBox__menu__top__tw">{{ locale === 'zh' ? item.titleTw : item.titleEn }}</span>
        </nuxt-link>
        <transition>
          <div class="mobileBox__menu__sub" v-show="isSubShow.type && isSubShow.index === index"
            v-if="item.sub?.length > 0">
            <nuxt-link :to="sub.link" :title="sub.title" v-for="(sub, subIndex) in item.sub" :key="subIndex"
              @click="closeMenu">
              {{ sub.title }}
            </nuxt-link>
          </div>
        </transition>
      </li>
    </ul>
    <div class="mobileBox__bottom">
      <nuxt-link to="/member/login" class="mobileBox__bottom__icon member" @click="closeMenu" v-if="isLogin === false">
        <svg-icon name="icon-member" />
      </nuxt-link>
      <nuxt-link to="/member" class="mobileBox__bottom__icon member" @click="closeMenu" v-if="isLogin === true">
        <svg-icon name="icon-member-bg" />
      </nuxt-link>

      <nuxt-link to="/cart" class="mobileBox__bottom__icon cart" @click="closeMenu">
        <svg-icon name="icon-cart" />
      </nuxt-link>

      <div class="mobileBox__bottom__lan">
        <div class="mobileBox__bottom__lan__inner" @click="setLocale('en')" v-if="locale == 'zh'">
          <svg-icon name="icon-lan-en" />
        </div>
        <div class="mobileBox__bottom__lan__inner" @click="setLocale('zh')" v-if="locale == 'en'">
          <svg-icon name="icon-lan-tw" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.mobileBox {
  position: fixed;
  right: 0;
  top: 55px;
  z-index: 200;
  width: 100%;
  max-width: 340px;
  padding: 30px 40px;
  background-color: #fff;
  box-shadow: -2px 10px 10px rgba(#000, 0.05);
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  @include max-media(480) {
    max-width: 240px;
  }

  &.isShow {
    opacity: 1;
    transform: translateX(0%);
  }

  &__menu {
    margin-bottom: 40px;

    li {
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &__sub {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;

      a {
        display: block;
        margin-bottom: 3px;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;

    &__icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__lan {
      width: 17px;
      margin-left: 16px;

      &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 17px;
        cursor: pointer;
      }

      svg {
        width: 100%;
      }
    }
  }
}
</style>
