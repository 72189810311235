<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
import { storeToRefs } from 'pinia'
import HeaderMenu from './HeaderMenu.vue'
import HeaderRight from './HeaderRight.vue'

const store = useGlobalStore()
const { isHeaderSearchShow } = storeToRefs(store)
</script>

<template>
  <header class="header" :class="{ show: isHeaderSearchShow }">
    <div class="wrap">
      <nuxt-link to="/" class="header__logo">
        <NuxtImg src="./images/logo.png" alt="iw logo"></NuxtImg>
      </nuxt-link>
      <HeaderMenu />
      <HeaderRight />
      <CartList />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.03);
  transform: translateY(0);
  transition: all 0.5s ease-in-out;

  &.show {
    transform: translateY(55px);
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
  }

  &__logo {
    display: inline-block;
    width: 100px;

    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
