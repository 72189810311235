<script lang="ts" setup>
import { useGlobalStore } from "@/stores";
import { storeToRefs } from "pinia";
const store = useGlobalStore();
const { menu } = storeToRefs(store);
</script>

<template>
  <nav>
    <ul class="menu">
      <li v-for="(item, index) in menu" :key="index">
        <nuxt-link :to="item.link" :title="item.titleTw" class="menu__top">
          <span class="menu__top__en">{{ item.titleEn }}</span>
          <span class="menu__top__tw">{{ item.titleTw }}</span>
        </nuxt-link>
        <div class="menu__sub" v-if="item.sub">
          <nuxt-link
            :to="sub.link"
            :title="sub.title"
            v-for="(sub, subIndex) in item.sub"
            :key="subIndex"
          >
            {{ sub.title }}
          </nuxt-link>
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.menu {
  display: flex;
  height: 100%;
  li {
    position: relative;
    font-size: 14px;
    &:hover {
      .menu {
        &__top {
          &__tw {
            transform: translateY(0%);
            opacity: 1;
            pointer-events: auto;
          }
          &__en {
            transform: translateY(-100%);
          }
        }
        &__sub {
          top: 100%;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  &__top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    height: 55px;
    overflow: hidden;
    @include max-media(1280) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @include max-media(1180) {
      display: none;
    }
    &__tw,
    &__en {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      white-space: nowrap;
    }
    &__tw {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(100%);
      width: 100%;
      height: 100%;
      text-align: center;
      font-family: $font-NotoSans;
      opacity: 0;
      pointer-events: none;
    }
    &__en {
      position: relative;
      height: 100%;
      font-family: $font-Yantramanav;
    }
  }
  &__sub {
    position: absolute;
    left: 50%;
    top: 120%;
    margin-left: -50px;
    width: 100px;
    background-color: #fff;
    border-top: 2px solid #000;
    box-shadow: 0 4px 12px #0000001a;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    a {
      display: block;
      padding: 8px 12px;
      text-align: center;
      font-family: $font-Yantramanav;
      font-family: $font-NotoSans;
      &:not(:last-child) {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
}
</style>
