<script lang="ts" setup>
import type { FormInstance } from 'element-plus/es/components/form'

import { useReCaptcha } from 'vue-recaptcha-v3'
import { useReCaptchaV3 } from '@/utils/helper'
import type { ISubscribeEmailParams } from '~/api/contact'

const useReCaptchaInstance = useReCaptcha()!
const { contact } = useApi()
const form = reactive({
  email: '',
})

const submitSuccess = ref(false)
const formRef = ref<FormInstance>()

const submitPedding = ref(false)

const handleSubmit = async () => {
  if (!formRef.value || isEmpty(form.email)) return false
  // 避免double click
  if (submitPedding.value === false) {
    submitPedding.value = true
    await formRef.value.validate(async (valid, invalidFields) => {
      if (valid) {
        const token = await useReCaptchaV3(useReCaptchaInstance, 'contact')

        const params = {
          tmp: 'xxx',
          email: form.email,
        } as ISubscribeEmailParams

        const { data, pending, error } = await contact.subscribeEmail(params)
        if (error.value) {
          // ElNotification({
          //   message: error.value.message,
          // })
        } else {
          formRef.value.resetFields()
          submitSuccess.value = true
          ElNotification({
            message: '已成功訂閱！',
          })
        }
      }
    })
    submitPedding.value = false
  }
}
</script>

<template>
  <div class="newsletter">
    <div class="newsletter__top">Sign up to our newsletter</div>
    <div class="newsletter__bottom">
      <el-form :model="form" ref="formRef" @submit.native.prevent="handleSubmit">
        <el-form-item prop="email" :rules="[
          {
            type: 'email',
            message: '請輸入正確的電子信箱格式',
          },
        ]">
          <el-input v-model="form.email" placeholder="Email" class="el-iw-input" />
        </el-form-item>
        <button class="buttonReset" type="button" @click="handleSubmit" :disabled="submitPedding">
          <svg-icon name="icon-arrow-right" />
        </button>
      </el-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.newsletter {
  width: 100%;
  max-width: 530px;

  &__top {
    margin-bottom: 20px;
    font-size: 10.8px;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(#000, 0.8);
  }

  &__bottom {
    position: relative;

    input {
      width: 100%;
      height: 38px;
      padding-left: 20px;
      padding-right: 30px;
      padding-bottom: 8px;
      border: 1px solid #000;
      border-top: none;
      border-right: none;
      border-radius: 0px;
      font-size: 14px;

      &::placeholder {
        color: #cecece;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30px;
      height: 38px;
      color: #000;

      svg {
        width: 21px;
        height: 8px;
      }
    }
  }
}

:deep(.el-form-item.is-error .el-input__wrapper) {
  box-shadow: unset;
}
</style>
