<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useGlobalStore } from '@/stores'
import { storeToRefs } from 'pinia'
const store = useGlobalStore()
const userStore = useUserStore()
const cartStore = useCartStore()
const { isMobileMenuShow, isHeaderSearchShow, isCartShow } = storeToRefs(store)
const { locale, setLocale } = useI18n()
const isLogin = computed(() => userStore.isLogin)
const hasItem = computed(() => cartStore.hasItem)

</script>

<template>
  <section class="hedaerRight">
    <div class="hedaerRight__icon search" @click="isHeaderSearchShow = true">
      <svg-icon name="icon-search" />
    </div>

    <div class="hedaerRight__icon member">
      <nuxt-link to="/member/login" v-if="isLogin === false"><svg-icon name="icon-member" /></nuxt-link>
      <nuxt-link to="/member" v-if="isLogin === true"><svg-icon name="icon-member-bg" /></nuxt-link>
    </div>

    <div class="hedaerRight__icon cart" :class="{'cart-no-empty': hasItem}" @click="isCartShow = true">
      <svg-icon name="icon-cart" />
    </div>

    <div class="hedaerRight__lan">
      <div class="hedaerRight__lan__inner" @click="setLocale('en')" v-if="locale == 'zh'">
        <svg-icon name="icon-lan-en" />
      </div>
      <div class="hedaerRight__lan__inner" @click="setLocale('zh')" v-if="locale == 'en'">
        <svg-icon name="icon-lan-tw" />
      </div>
    </div>

    <button class="buttonReset mobileButton" :class="{ isShow: isMobileMenuShow }"
      @click="isMobileMenuShow = !isMobileMenuShow"></button>
  </section>
</template>

<style lang="scss" scoped>
.hedaerRight {
  display: flex;
  align-items: center;

  &__icon {
    margin-left: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__lan {
    width: 17px;
    margin-left: 16px;

    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 17px;
      cursor: pointer;
    }

    svg {
      width: 100%;
    }
  }

  .search,
  .cart {
    cursor: pointer;
  }

  .member,
  .cart,
  &__lan {
    @include max-media(1180) {
      display: none;
    }
  }

  .cart-no-empty {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      right: 3px;
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
      transform: translate(50%, -50%);
    }
  }

  .mobileButton {
    @include min-media(1181) {
      display: none;
    }

    position: relative;
    width: 24px;
    height: 55px;
    margin-left: 15px;
    background-color: #fff;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #000;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      margin-top: -4px;
    }

    &::after {
      margin-top: 4px;
    }

    &.isShow {
      &::before {
        transform: rotate(45deg);
        margin-top: -0.5px;
      }

      &::after {
        transform: rotate(-45deg);
        margin-top: -0.5px;
      }
    }
  }
}
</style>
